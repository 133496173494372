body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Courier New !important;
}
.MuiTypography-root{
  font-family: Courier New !important;
}
.OnePirateTypography-markedH4Center{
  background-color: rgba(158, 46, 46, 0.5) !important;
}