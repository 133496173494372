.nameText {
  letter-spacing: .05em !important;
  font-weight: 700 !important;
  font-size: 3rem !important;
  line-height: 1 !important;
  z-index: 2;
  font-family: Permanent Marker, Work Sans, sans-serif !important;
}

.subText {
  letter-spacing: .05em !important;
  font-weight: 700 !important;
  font-size: 1.5rem !important;
  line-height: 1 !important;
  z-index: 2;
  font-family: Permanent Marker, Work Sans, sans-serif !important;
}

.aboutmeImg {
  border-radius: 40%;
  height: 295px;
  width: 100%;
  margin-top: 36%;
}

.MuiChip-label {
  color: #fff;
  font-size: 1.20rem !important;
  text-transform: uppercase;
  letter-spacing: .05em !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  z-index: 2;
  font-family: Permanent Marker, Work Sans, sans-serif !important;
}

.MuiChip-sizeMedium {
  font-size: 1.81rem !important;
  background-color: rgba(158, 46, 46, 0.5) !important;
  height: 44px !important;
}

.header-title {
  font-size: 1.125rem;
  padding-left: 10px;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  line-height: 2;
}

.careers-div {
  margin-bottom: unset !important;
}

.time-line {
  margin-right: 23%;
  width: 100%;
}

.position-title {
  font-weight: 700 !important;
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.company-name {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

.MuiTypography-alignRight {
  text-transform: uppercase;
  line-height: 2 !important;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-size: 1em !important;
  margin: 6px !important;
}

.css-v6mo1y-MuiTimelineDot-root {
  padding: 2px !important;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}

.css-y8siq2-MuiContainer-root {
  margin-bottom: unset !important;
}

.education-div {
  margin-bottom: unset !important;
  margin-top: unset !important;
}

.footer-text {
  font-family: Permanent Marker, Work Sans, sans-serif !important;
}

.main-footer {
  --tw-bg-opacity: 1;
  padding: 1.5rem;
  text-align: center !important;
}

.link-footer {
  background-color: unset !important;
}

.footer {
  background-color: rgba(158, 46, 46, 0.5);
  padding: 10px;
  text-align: center;
  bottom: 0;
  width: 100%;
  margin-top: unset !important;
  margin-bottom: unset !important;
  width: 100% !important;
  max-width: 100% !important;
  color: #fff;
}

.social-linked {
  font-size: 2rem !important;
  color: #fff !important;
}

.footer .css-i4bv87-MuiSvgIcon-root {
  width: 1.5em !important;
  height: 1.5em !important;
}

.MuiTypography-h5 {
  font-weight: 700 !important;
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.header-project {
  margin-bottom: 32px !important;
}

.position-title {
  color: rgba(0, 0, 0, 0.6) !important;
}

@media only screen and (max-width: 767px) {

  .project-container {
    margin-left: 25% !important;
  }
  .about-me{
    margin-top: unset !important;
  }
}